import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, _toDisplayString(_ctx.cartItem.product.name), 1),
    _createElementVNode("td", null, [
      _withDirectives(_createElementVNode("input", {
        type: "number",
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cartItem.quantity) = $event)),
        min: "0"
      }, null, 512), [
        [_vModelText, _ctx.cartItem.quantity]
      ])
    ]),
    _createElementVNode("td", null, _toDisplayString(_ctx.cartItem.product.price), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.cartItem.product.price * _ctx.cartItem.quantity), 1),
    _createElementVNode("td", null, [
      _createElementVNode("button", {
        class: "btn-close",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.removeFromCart && _ctx.removeFromCart(...args)))
      })
    ])
  ]))
}