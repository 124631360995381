
import { defineComponent } from 'vue'
import CartTable from '@/components/cart/CartTable.vue';

export default defineComponent({
  name: 'Cart',
  components: {
    CartTable
  }
})
