
import { defineComponent } from 'vue'
import CartTableRow from '@/components/cart/CartTableRow.vue';

export default defineComponent({
  name: 'Cart',
  components: {
    CartTableRow
  }
})
